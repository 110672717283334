import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/common/layout';
import { Heading } from 'components/common/typography';
import { renderAst } from 'lib/cms';
// import { Article, Header } from 'components/pages/category/template';
import { ImageGrid, Article, Header, GalleryContainer } from 'components/pages/category/template';
import Card from 'components/pages/category/card';
import { getImageMeta } from 'templates/category';
import SEO from 'components/common/seo';

const Category = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    
    return (
        <Layout>
            <SEO 
                title={frontmatter.title}
            />
            <Article>          
                <Header>
                    <Heading appearance="trafalgar">{frontmatter.title}</Heading>
                </Header>
                <div>{renderAst(data.markdownRemark.htmlAst)}</div>
                <GalleryContainer>
                    <ImageGrid> 
                        {data.images.edges.map(({ node }, i) => {
                            const { title, manufacturer } = getImageMeta(node.base);
                            return (
                                <Card key={i} src={node.childImageSharp.fluid} title={title} manufacturer={manufacturer} />
                            )
                        })}
                    </ImageGrid>
                </GalleryContainer>
            </Article>
        </Layout>
    );
};

export default Category;

export const servicesQuery = graphql`
    query($path: String!, $imagesRegex: String!) {
        markdownRemark(frontmatter: { path: { eq: $path }}) {
            htmlAst
            frontmatter {
                title
            }
        }
        images: allFile(
            filter: {
                absolutePath: {regex: $imagesRegex}, 
                extension: {regex: "/(jpg)|(jpeg)|(png)/"}
            },
            sort: {
                fields: base,
                order: ASC
            }
        ) {
            edges {
                node {
                    base
                    childImageSharp {
                        fluid(maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;